@import 'styles/includes.scss';

.GuidePage {
    background-color: $colorGray10;
    flex: 1;
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: none;
    }

    &__Main {
        display: block;
    }

    &__Section {
        display: flex;
        justify-content: space-between;

        &--Grid {
            @extend %container;
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;

        @include media(m) {
            max-width: $maxWidthText;
        }
        @include media(ml) {
            margin-right: 20px;
        }

        .GuidePage__ExtendedByline {
            display: block;
            max-width: 440px;

            @include media(ml) {
                display: none;
            }
        }
    }

    &__Row {
        margin-bottom: $spacingS;
    }

    &__Aside {
        display: none;
        width: 40.4rem;

        @include media(ml) {
            display: flex;
            margin-top: $spacingXl * 2;
            justify-content: flex-end;
        }
    }

    &__ObjectsContainer {
        background-color: $colorWhite;
        border-top: $colorGray200 1px solid;
        border-bottom: $colorGray200 1px solid;

        @include media(ml) {
            margin-top: 5rem;
        }
    }

    &__ObjectsContent {
        @extend %container;
    }

    &__RelatedCardsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding-bottom: 8rem;
        background-color: $colorBlack800;

        &--EmptyList {
            margin-top: 139px;
        }

        &--Hidden {
            display: none;
            opacity: 0;
            transition: opacity 0.3s;
        }

        &--isVisible {
            opacity: 1;
            display: block;
        }
    }

    &__Related {
        @extend %container;
        margin: 8rem auto;
    }

    &__RelatedTitle {
        @extend %h2;
        margin-bottom: 2rem;
    }

}
