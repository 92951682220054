@import 'styles/includes.scss';

@keyframes slideIn {
    0% {
        transform: translateX(100%);
        opacity: 0.1;
    }
    50% {
        opacity: 0.3;
    }
    60% {
        opacity: 0.4;
    }
    70% {
        opacity: 0.5;
    }
    80% {
        opacity: 0.7;
    }
    90% {
        opacity: 0.8;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInImage {
    0% {
        transform: translateX(60%);
        opacity: 0;
    }
    // 20% {
    //     transform: translateX(40%);
    //     opacity: 0;
    // }
    30% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.1;
    }
    60% {
        opacity: 0.3;
    }
    70% {
        opacity: 0.5;
    }
    80% {
        opacity: 0.7;
    }
    90% {
        opacity: 0.8;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-16rem);
    }
    100% {
        transform: translateY(0rem);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-16rem);
    }
}
@keyframes slideDownMegaMenu {
    0% {
        transform: translateY(-50rem);
    }
    100% {
        transform: translateY(0rem);
    }
}

@keyframes slideUpMegaMenu {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50rem);
    }
}


@keyframes fadeInSearchOverlay {
    0% {
        display: block;
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOutSearchOverlay {
    0% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        display: none;
    }
}


.PatchBlack {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 91px;
    background-color: $colorBlack800;
    z-index: 01;
    height: 95px;

    @include media(ls) {
        height: 91px;
    }
}

.Header {


    position: relative;
    background-color: $colorBlack800;
    z-index: 10001;

    &Heart {
        margin-left: 6px;
        margin-right: 4px;
        width: 26px;
        height: 26px;

        @include media(l) {
            margin-left: 5px;
            margin-right: 4px;
            width: 18px;
            height: 18px;
        }
    }

    &__ServiceMenu {
        background-color: $colorBlack800;
        display: none;

        @include media(ls) {
            display: block;
        }
    }

    &__ServiceMenuInner {
        height: 4rem;
        @extend %container;
        color: $colorWhite;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__ServiceMenuIconWrapper {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        background: none;
        border: none;
        padding: 8px 12px;
        height: 40px;
        cursor: pointer;
    }

    &__ServiceMenuIcon {
    }

    &__ServiceMenuIconLabel {
        font-size: 1.2rem;
        color: $colorWhite;
        font-family: $fontBrand;
        font-weight: normal;
    }

    &__Nav {
        background-color: $colorBlack800;
    }

    &__Inner {
        $root: &;

        @extend %container;
        color: $colorWhite;
        height: 95px;
        font-family: $fontBrand;
        font-weight: normal;
        display: flex;
        justify-content: space-between;

        align-items: center;

        @include media(ls) {
            justify-content: flex-start;
            align-items: flex-start;
            height: 73px;
            padding-top: 1rem;
        }
    }

    &__ExpandedMenu {
        @extend %container;
        overflow: auto;
        position: fixed;
        width: 100%;
        height: 130vh;
        z-index: 100001;
        top: 0;
        left: 0;
        right: 0;
        background: url('#{$assetPath}img/pattern-mobile.svg') no-repeat right
                bottom,
            $colorBlack800;
        transform: translateX(0);
        transition: transform 0.4s ease-out, left 0.4s ease-out;

        @include media(xs) {
            height: 100vh;
        }

        @include media(ls) {
            display: none;
        }

        &--Hide {
            transform: translateX(100%);
            left: 100%;

            & > div,
            ul {
                display: none;
            }
        }

        .Header__Inner {
            height: auto;
        }

        &--MegaMenuOpen {
            background: $colorBlack800;
        }
    }

    a {
        color: $colorWhite;
        text-decoration: none;
    }

    &__Logo {
        width: 15.1rem;
        height: 3.6rem;

        @include media(ls) {
            width: 17.6rem;
            height: 4.2rem;
        }

        .Header--PublicApi & {
            padding-right: 1.6rem;
            border-right: 1px solid $colorWhite;
        }
    }

    &__Brand {
        position: relative;
        line-height: 0;
        z-index: 11;
        // Fix so z-index works in Safari
        -webkit-transform: translate3d(0, 0, 0);

        @include media(ls) {
            width: 176px;
            margin-right: 34px;
        }

        .Header--PublicApi & {
            display: flex;
            align-items: center;
            line-height: normal;
            width: auto;
        }
    }

    &__BrandLink {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &__BrandTitle {
        padding-left: 1.6rem;
        position: relative;
        bottom: 2px;
    }

    &__Content {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include media(ls) {
            flex-direction: row;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
        }
    }

    &__SearchButton {
        z-index: 11;
        // Fix so z-index works in Safari
        -webkit-transform: translate3d(0, 0, 0);
    }

    &__MainNav--Desktop {
        display: none;

        @include media(ls) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
        }
        @include media(l) {
            margin-left: 24px;
        }
    }

    &__MainNav {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: $spacingL;

        &--Expanded {
            @for $i from 1 through 10 {
                li:nth-child(#{$i + 1}) {
                    animation: slideIn (($i) * 0.2s);
                }
            }
        }
    }

    &__MobileServiceMenu {
        display: flex;

        @include media(ls) {
            display: none;
        }
    }

    &__LanguageDesktop {
        display: none;

        @include media(ls) {
            display: block;
        }
    }

    &__LanguageMobile {
        margin-right: 20px;
        z-index: 1;
    }

    &__MainNavItem {
        margin: $spacingL 0;
        font-family: $fontBrand;
        font-weight: bold;
        font-size: 2.6rem;
        letter-spacing: 0.04rem;
        line-height: 1.32;
        box-sizing: border-box;
        overflow: hidden;

        @include media(ls) {
            display: inline-block;
            font-family: $fontBrand;
            font-weight: normal;
            height: 100%;
            font-size: 1.7rem;
            letter-spacing: 0.02rem;
            margin: 0 20px;
        }

        @include media(l) {
            margin: 0 10px;
        }

        &:last-child {
            margin-right: 10px;
        }
    }

    &__MainNavItemLink {
        background-color: transparent;
        font-family: $fontBrand;
        border: none;
        color: $colorWhite;
        display: inline-flex;
        flex-direction: column;
        position: relative;
        padding: 0 0;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        margin-top: auto;
        cursor: pointer;

        &--MegaMenu span.Header__MainNavItemLabel {
            font-weight: bold;
            font-size: 1.9rem;

            @include media(ls) {
                font-weight: normal;
                font-size: 1.5rem;
                line-height: 22px;
            }
        }

        &:focus {
            color: $colorPink;

            @include media(ls) {
                color: $colorWhite;
                height: 100%;
                &:after {
                    left: 0;
                    height: 4px;
                    right: auto;
                    width: 100%;
                    background: $colorPink;
                    content: '';
                    bottom: 0;
                    position: absolute;
                    transition: width 400ms cubic-bezier(0.79, 0.14, 0.16, 0.86);
                }
            }
        }

        @include media(ls) {
            height: 100%;
            justify-content: center;
        }

        &--Active {
            // @todo, Vad kör vi för active-state i mobil?

            @include media(ls) {
                height: 100%;

                & .Header__MainNavItem--Hover {
                    width: 100%;
                    height: 4px;
                    margin-top: auto;
                    display: block;

                    &:after {
                        left: 0;
                        height: 4px;
                        right: auto;
                        width: 100%;
                        background: $colorPink;
                        content: '';
                        position: absolute;
                        transition: width 400ms
                            cubic-bezier(0.79, 0.14, 0.16, 0.86);
                    }
                }
            }
        }

        .Header__MainNavItemIcon {
            display: none;
            transform: rotate(90deg);
            margin-left: 10px;
            transition: transform 0.2s ease-in-out;

            &--Open {
                transform: rotate(-90deg);
            }

            @include media(ls) {
                display: block;
            }
        }

        .Header__MainNavItemLabel {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 0.8rem;
            font-size: 2.1rem;

            @include media(ls) {
                gap: 0;
                margin-top: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 0.8rem;
                padding-right: 0.8rem;
                font-size: 1.7rem;
             }
        }

        .Header__MainNavItemChevronMobile {
            display: block;
            position: relative;
            top: 2px;

            @include media(ls) {
                display: none;
            }
        }
        .Header__MainNavItemLabelHeart {
            display: flex;
            align-items: center;
        }

        &:not(&--Active) {
            & .Header__MainNavItem--Hover {
                display: none;

                @include media(ls) {
                    display: block;
                    margin-top: auto;
                    overflow: hidden;
                    position: relative;
                    height: 4px;
                    width: 100%;
                    &:after {
                        background: $colorPink;
                        content: '';
                        height: 4px;
                        position: absolute;
                        right: 0 !important;
                        left: auto !important;
                        width: 0;
                        transition: width 400ms
                            cubic-bezier(0.79, 0.14, 0.16, 0.86);
                    }
                }
            }

            &:hover {
                @include media(ls) {
                    & .Header__MainNavItem--Hover {
                        width: 100%;

                        &:after {
                            left: 0 !important;
                            height: 4px;
                            right: auto;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__Toggle {
        background: none;
        height: 27px;
        border: none;
        padding-right: 0;
        margin-left: $spacingS;
        padding-left: 0;

        @include media(xs) {
            margin-left: $spacingL;
            padding-bottom: 2px;
        }

        @include media(ls) {
            display: none;
        }
    }

    &__ToggleBurger {
        display: block;
        height: 2px;
        margin-bottom: 7px;
        background: $colorWhite;

        &--1 {
            width: 10px;
            margin-left: 14px;
        }

        &--2 {
            width: 18px;
            margin-left: 6px;
        }

        &--3 {
            width: 24px;
            margin-bottom: 0;
        }
    }

    &__ToggleLabel {
        display: block;
        font-size: 10px;
        margin-top: -5px;
        text-align: right;
        color: $colorWhite;

        @include media(ls) {
            display: none;
        }
    }

    &__MobileHeader {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        height: 88px;
        font-family: $fontBrand;
        font-weight: normal;
        position: relative;

        @include media(ls) {
            display: none;
        }

        &--Close {
            background: none;
            border: none;
        }
    }

    &__MobileHeaderHeadline {
        width: calc(100% - 160px);
        font-size: 1.4rem;
        letter-spacing: 0.26rem;
        text-transform: uppercase;

        .Header--PublicApi & {
            width: 100%;
        }
    }

    &__DropDown {
        transform: translateY(-20px);
        width: 118px;
        font-size: 1.7rem;
        letter-spacing: 0.02rem;
        //Customize react select
        @include media(ls) {
            font-size: 1.4rem;
            height: 67px;
            transform: translateY(0);
        }
    }

    &__LabeldIconContainer {
        @include media(ls) {
            border-right: 1px solid $colorGray700;
        }
    }

    &__LabeledIcon,
    &__ServiceLabeledIcon {
        position: relative;
        margin: 0 $spacingM;
        align-self: center;

        button {
            padding: 0;
        }

        @include media(s) {
            margin: 12px $spacingL;
        }
    }

    &__ServiceLabeledIcon {
        button {
            display: flex;
            gap: 0.6rem;
        }

        @include media(s) {
            margin: 0 12px;
        }
    }

    &__CenteredLabel {
        align-self: center;
    }

    &__LabeledIconLabel {
        font-size: 10px;
        margin-top: 1px;
        text-align: center;
        color: $colorWhite;
    }

    &__SavedCounter,
    &__ServiceSavedCounter {
        background: $colorPink;
        position: absolute;
        top: -6px;
        right: auto;
        left: 7px;
        justify-content: center;
        align-items: center;

        height: 1.6rem;
        width: 1.6rem;
        border-radius: 50%;
        font-size: 1rem !important;
        line-height: 0.1;
        margin-top: 0;
        display: inherit !important;

        &:empty {
            display: none;
        }
    }

    &__SerivceSavedCounter {
        top: -4px;
        right: -4px;
    }

    &__SearchModalWrapper {
        z-index: -1;
        position: relative;
        will-change: transform, opacity;
        transform: translateY(-16rem);

        &::after {
            content: '';
            position: absolute;
            top: 9.5rem;
            left: 0;
            width: 100vw;
            background-color: rgba($colorBlack800, 0.4);
            z-index: -1;
            opacity: 0;
        }

        &--Active {
            animation: slideDown 0.4s ease forwards;

            &::after {
                height: 200vh;
                animation: fadeInSearchOverlay 0.4s ease forwards;
            }
        }

        &--Hide {
            animation: slideUp 0.4s ease forwards;

            &::after {
                height: 200vh;
                animation: fadeOutSearchOverlay 0.4s ease forwards;
            }
        }
    }

    &__MegaMenuDesktop {
        display: none;

        @include media(ls) {
            display: block;
            position: relative;

            z-index: -1;
            position: relative;
            will-change: transform, opacity;
            transform: translateY(-50rem);

            &::after {
                content: '';
                position: absolute;
                top: 9.5rem;
                left: 0;
                width: 100vw;
                background-color: rgba($colorBlack800, 0.4);
                z-index: -1;
                opacity: 0;
            }
        }

        &--Active {
            animation: slideDownMegaMenu 0.4s ease forwards;

            &::after {
                height: 200vh;
                animation: fadeInSearchOverlay 0.4s ease forwards;
            }
        }

        &--Hide {
            animation: slideUpMegaMenu 0.4s ease forwards;

            &::after {
                height: 200vh;
                animation: fadeOutSearchOverlay 0.4s ease forwards;
            }
        }
    }

    &__MegaMenuMobile {
        display: block;

        @include media(ls) {
            display: none;
        }
    }

    &__MegaMenu {
        position: relative;
        top: 0;
        width: 100%;

        @include media(ls) {
            position: absolute;
            background-color: $colorBlack800;
            // top: calc(73px + 40px);

            // &::after {
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     bottom: 0;
            //     width: 100vw;
            //     background-color: rgba($colorBlack800, 0.4);
            //     z-index: -1;
            //     opacity: 1;
            //     height: 200vh;
            // }
        }
    }

    &__MegaMenuWrapper {
        margin-left: auto;
        margin-right: auto;
        padding: 0;

        @include media(ls) {
            padding: 0 $spacingXl * 8;
        }

        @include media(xl) {
            max-width: (map-get($maxwidths, xl));
            padding: 0 10.6rem;
        }
    }

    &__MegaMenuContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        padding: 0;

        @include media(ls) {
            padding: 5.6rem 0 6.4rem 0;
            flex-direction: row;
        }
    }

    &__MegaMenuLeft {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 4rem;

        @include media(ls) {
            padding-bottom: 0;
        }
    }

    &__MegaMenuRight {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__MegaMenuImageContainer {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;

        @include media(ls) {
            flex-direction: row;
        }

        .Header__MegaMenuCard {
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    animation: slideInImage ($i * 0.5s);
                }
            }

            @include media(ls) {
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        animation: none;
                    }
                }
            }
        }
    }

    &__MegaMenuTitleContainer {
        display: flex;
        flex-direction: row;
        gap: 1.2rem;
    }

    &__MegaMenuTitleWrapper {
        display: inline-block;
        gap: 1.2rem;
        margin-bottom: 2rem;
        width: fit-content;

        @include media(ls) {
            margin-bottom: 4rem;
        }

        .Header__MegaMenuTitle--Hover {
            display: none;

            @include media(ls) {
                position: absolute;
                display: block;
                margin-top: auto;
                // overflow: hidden;
                position: relative;
                height: 2px;
                width: 100%;
                &:after {
                    background: $colorPink;
                    content: '';
                    height: 2px;
                    position: absolute;
                    right: 0 !important;
                    left: auto !important;
                    width: 0;
                    top: 4px;
                    transition: width 400ms cubic-bezier(0.79, 0.14, 0.16, 0.86);
                }
            }
        }

        &:hover {
            @include media(ls) {
                & .Header__MegaMenuTitle--Hover {
                    width: 100%;

                    &:after {
                        left: 0 !important;
                        height: 2px;
                        right: auto;
                        width: 100%;
                    }
                }
            }
        }
    }

    &__MegaMenuTitle {
        font-size: 2.2rem;
        font-weight: 700;
        color: $colorWhite;
        line-height: 26px;
        letter-spacing: 0.5px;
    }

    &__MegaMenuLinks {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 100%;
            background-color: $colorGray700;
        }
    }

    &__MegaMenuLinkWrapper {
        margin-left: 1.6rem;
        font-size: 1.6rem;

        width: fit-content;
        position: relative;
        display: inline-block;
        color: $colorWhite;
        text-decoration: none;
        cursor: pointer;

        @include media(ls) {
            margin-left: 3.2rem;
            font-size: 1.8rem;
        }

        .Header__MegaMenuLink--Hover {
            display: none;

            @include media(ls) {
                display: block;
                margin-top: auto;
                overflow: hidden;
                position: relative;
                height: 2px;
                width: 100%;
                &:after {
                    background: $colorPink;
                    content: '';
                    height: 2px;
                    position: absolute;
                    right: 0 !important;
                    left: auto !important;
                    width: 0;
                    transition: width 400ms cubic-bezier(0.79, 0.14, 0.16, 0.86);
                }
            }
        }

        &:hover {
            @include media(ls) {
                & .Header__MegaMenuLink--Hover {
                    width: 100%;

                    &:after {
                        left: 0 !important;
                        height: 2px;
                        right: auto;
                        width: 100%;
                    }
                }
            }
        }
    }

    &__MegaMenuCard {
        @include media(ls) {
            &--Half {
                max-width: 190px !important;
                min-width: 190px !important;
                width: 100%;
            }

            &--Full {
            }
        }
    }

    &__MegaMenuBackButton {
        font-family: $fontBrand;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        font-size: 1.6rem;
        color: $colorWhite;
        cursor: pointer;
        background-color: transparent;
        border: none;
        height: 2.6rem;
        margin: 1.2rem 0 4rem 0;
        padding: 0;
        font-size: 1.6rem;
        font-weight: 400;

        svg {
            transform: rotate(180deg);
        }

        @include media(ls) {
            display: none;
        }
    }

    &__MegaMenuTitleIcon {
        display: none;

        @include media(ls) {
            display: block;
        }
    }
}
