@import 'styles/includes.scss';

.ShareModal {
    background-color: $colorGray10;
    width: 100%;
    max-width: 46.6rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $fontBrand;
    color: $colorBlack;
    font-stretch: normal;
    font-style: normal;
    position: relative;
    padding: $spacingXl $spacingL;

    @include media(m) {
        height: 32.9rem;
        padding: $spacingL * 2;
    }


    &__ModalTitle {
        font-size: 1.9rem;
        font-weight: bold;
        line-height: 1.68;
        letter-spacing: 0.04rem;
        margin-bottom: $spacingXl;
    }

    &__ModalButtonsWrapper {
        width: calc(300px - calc(83px * 2));
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacingL * 2;

        @include media(m) {
            margin-bottom: 4rem;
        }
    }

    &__ModalInput {
        width: 100%;
        border-radius: 10px;
        border: solid 1px $colorGray500;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.65;
        letter-spacing: normal;
        color: $colorGray500;
        padding: $spacingM $spacingL;
        margin-bottom: $spacingXl;
    }

    &__ModalCloseButton {
        position: absolute;
        top: $spacingL;
        right: $spacingL;
        background: none;
        border: none;
        padding: 0;
    }
}
