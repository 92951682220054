@import 'styles/includes.scss';

.Footer {
    font-family: $fontBrand;
    color: $colorWhite;
    background: url('#{$assetPath}img/pattern-mobile.svg') no-repeat right bottom,
    $colorBlack800;

    @include media(m) {
        background: none;
        background-color: $colorBlack800;
    }

    &__Grid {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 $spacingL;

        @include media(s) {
            padding: 0 $spacingL;
        }

        @include media(ml) {
            padding: 0 $spacingXl * 3;
        }

        @include media(l) {
            padding: 0 $spacingXl * 4;
        }

        @include media(xl) {
            max-width: (map-get($maxwidths, xl));
            padding: 0;
        }
    }

    &__TopWrapper {
        padding-top: 4rem;
        background: none;

        @include media(m) {
            width: 100%;
            min-height: 20.3rem;
            display: flex;
            align-items: flex-end;
            background: url('#{$assetPath}img/desktop-background-top.svg') no-repeat right bottom,
            $colorBlack800;
            background-size: auto 100%;
            padding-bottom: 4rem;
        }

        @include media(ml) {
            padding-top: 6.2rem;
        }
    }

    &__Flex {
        display: flex;
        flex-flow: row wrap;
    }

    &__SocialMediaSectionWrapper {
        @include media(m) {
            display: flex;
            width: 50%;
            flex-direction: column;
        }

        @include media(l) {
            flex-direction: row;
        }
    }

    &__SocialMediaSection {
        margin-bottom: 4rem;

        @include media(m) {
            display: block;
            width: 100%;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        @include media(l) {
            margin-top: $spacingM;
            margin-bottom: 0;
        }
    }

    &__SocialMediaLabel {
        display: block;
        font-size: 1.7rem;
        font-weight: bold;
        line-height: 1.41;
        letter-spacing: 0.02px;

        @include media(ml) {
            font-size: 1.9rem;
            line-height: 1.68;
            letter-spacing: 0.04px;
        }
    }

    &__SocialMediaList {
        display: flex;
        flex-wrap: wrap;
        padding-top: $spacingL;
    }

    &__SocialMediaLink:not(:last-child) {
        margin-right: $spacingL;

        @include media(m) {
            margin-right: $spacingL;
        }

        @include media(ml) {
            margin-right: $spacingS;
        }

        @include media(l) {
            margin-right: $spacingL;
        }
    }

    &__BrandContainer {

        @include media(m) {
            display: flex;
            justify-content: flex-start;
        }

        @include media(l) {
            justify-content: flex-end;
        }
    }

    &__LogoWrapper {
        width: 50%;
    }

    &__BrandLinkWrapper {
        position: relative;
        display: inline-block;
        padding-right: 2rem;

        img {
            width: 22rem;
        }

        @include media(m) {
            display: none;
        }

        &--Desktop {
            display: none;

            @include media(m) {
                display: inline-block;
                padding-right: 2rem;
            }
        }
    }

    &__Brand {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__BottomWrapper {
        background: none;

        @include media(m) {
            background: url('#{$assetPath}img/desktop-background-bottom.svg') no-repeat right top,
            $colorBlack800;
            background-size: auto 100%;
            padding-top: 2rem;
        }
    }

    &__CookieConsentWrapper {
        margin: 20px 0;
    }

    &__Nav {
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
        padding-top: 4rem;
        padding-bottom: 23.3rem;

        @include media(m) {
            flex-flow: row wrap;
            padding-bottom: 0.9rem;
            min-height: 40.4rem;
        }

        @include media(l) {
            padding-top: 6.2rem;
        }
    }

    &__NavSection {
        @include media(m) {
            width: 50%;
            margin-bottom: $spacingXl * 2;
        }

        @include media(ml) {
            width: 50%;
        }

        @include media(l) {
            width: 25%;
            margin-bottom: 0;
        }


    }

    &__NavSection:not(:first-of-type){

        @include media(l) {
            margin-top: 2rem;
        }
    }

    &__NavLabel {
        font-size: 1.2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2.6px;
        text-transform: uppercase;
        display: block;
        margin-bottom: 20px;

        @include media(m) {
            margin-bottom: 32px;
        }

        @include media(l) {
            font-size: 1.4rem;
        }
    }

    &__NavList {
        margin-bottom: $spacingXl * 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include media(m) {
            margin-bottom: 0;
        }
    }

    &__NavItem {
        position: relative;
    }

    &__NavLink:focus,
    &__NavItem:hover,
    &__NavItem:focus {
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: white;
            bottom: 0;
            left: 0;
        }
    }

    &__NavItem:not(:last-child) {
        margin-bottom: 20px;

        @include media(m) {
            margin-bottom: 24px;
        }
    }

    &__NavLink {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__NavLinkText {
        display: inline-block;
        text-decoration: none;
        color: $colorWhite;
        font-size: 1.7rem;
        letter-spacing: 0.3px;
    }

    &__NavIconWrapper {
        display: inline-block;
        margin-left: $spacingS;
        position: relative;
        top: 2px;

        svg {
            height: 18px;
            width: 18px;
        }
    }

    &__ContactLinks {
        margin-bottom: 1rem;
    }

    &__Contact {
        margin-bottom: 4rem;

    }
    &__Phone {

        margin-bottom: 2.2rem;

        width: fit-content;
        position: relative;
        display: flex;
        &:before {
            content: '';
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
            width: 24px;
            height: 24px;
            background-image:  url('#{$assetPath}img/icon-phone.svg') ;
            background-repeat: no-repeat;
            background-size: contain;
          }
    }


    &__Email {
        width: fit-content;
        position: relative;
        display: flex;
        &:before {
            content: '';
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
            width: 24px;
            height: 24px;
            background-image:  url('#{$assetPath}img/icon-email.svg') ;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
    }

    &__NewUnderline {
        color: $colorWhite;
        font-size: 1.7rem;
        text-decoration: none;
        width: fit-content;
        position: relative;
    }
    &__NewUnderline:hover {
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: white;
            bottom: 0;
            left: 0;
        }
    }


    &__Address {
        margin-bottom: 2rem;
    }

    &__Name {}

    &__Street {}

    &__Zip {}
}
