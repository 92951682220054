@import 'styles/includes.scss';
@import 'styles/variables.scss';

.Icon {
    $root: &;
    display: flex;
    align-self: center;
    align-content: center;

    &--Round {
        height: 3.8rem;
        width: 3.8rem;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        background-color: $colorBlack750;
        transition: background-color $transition;

        &:hover,
        &:focus {
            background-color: $colorPink;
        }
    }

    &--Round.Icon--ModalSize {
        height: 4.5rem;
        width: 4.5rem;
    }

    &--Round > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    svg {
        height: 100%;
        width: auto;
        max-width: 24px;
        max-height: 24px;
    }

    &--Round svg {
        transition: color $transition;
    }

    &--Xs {
        height: 12px;
        width: 12px;
    }

    &--Small {
        height: 16px;
        width: 16px;
    }
    &--Sm {
        height: 18px;
        width: 18px;
    }

    &--Medium {
        height: 16px;
        width: 16px;

        @include media(l) {
            height: 24px;
            width: 24px;
        }
    }

    &--Large {
        height: 2.4rem;
        width: 2.4rem;
        .Icon--DatePicker & {
            height: 20px;
            width: 20px;
        }
    }
    &--Fill {
        width: 100%;
        height: auto;

        svg {
            max-width: none;
            max-height: none;
        }
    }

    &--SizeXLMobile {
        width: 4.8rem;
        height: 4.8rem;
        padding: 4px;

        @include media(m) {
            padding: 0;
            height: 2.4rem;
            width: 2.4rem;
        }
    }

    &--Xl {
        @include media(l) {
            height: 3.6rem;
            width: 3.6rem;
        }
    }

    &:hover &--HoverBlack800 {
        svg {
            color: $colorBlack800;
        }
    }

    &:hover &--HoverWhite {
        svg {
            color: $colorWhite;
        }
    }

    &:hover &--HoverPink {
        svg {
            color: $colorPink;
        }
    }

    &__White {
        color: $colorWhite;
    }

    &--Black {
        svg {
            color: $colorBlack;
        }
    }

    &--Black750 {
        svg {
            color: $colorBlack750;
        }
    }

    &--Black800 {
        svg {
            color: $colorBlack800;
        }
    }

    &--Error {
        svg {
            color: $colorErrorMessage;
        }
    }

    &--Gray10 {
        svg {
            color: $colorGray10;
        }
    }

    &--Gray100 {
        svg {
            color: $colorGray100;
        }
    }

    &--Gray500 {
        svg {
            color: $colorGray500;
        }
    }

    &--Gray700 {
        svg {
            color: $colorGray700;
        }
    }

    &--Pink {
        svg {
            color: $colorPink;
        }
    }

    &--Pink700 {
        svg {
            color: $colorPink700;
        }
    }

    &--Orange {
        svg {
            color: $colorOrange;
        }
    }

    &--White {
        svg {
            color: $colorWhite;
        }
    }
}
