@import 'styles/includes.scss';

.BasePage {
    background: $colorWhiteBackground;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
