@import 'styles/includes.scss';
@import 'styles/variables.scss';

.Byline {
    $root: &;
    display: flex;
    flex-direction: row;

    &__Info {
        min-height: 64px;
        font-family: $fontBrand;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        #{$root}--Card & {
            min-height: 52px;
        }

        &--Light {
            color: $colorWhite;
            text-shadow: 1px 0px 2px rgba(0, 0, 0, 0.5);
        }

        &--Dark {
            color: $colorBlack800;
        }
    }

    &__Image {
        padding-right: 12px;

        #{$root}--Card & {
            padding-right: 0;
            margin-right: 12px;
            width: 52px;
            height: 52px;
            @include media(m) {
                padding-right: 12px;
                margin-right: 0;
                width: auto;
                height: auto;
            }
        }
    }

    &__Name {
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.1px;
        font-weight: bold;

        #{$root}--Card & {
            font-size: 1.2rem;
            @include media(m) {
                font-size: 1.6rem;
            }
        }
    }

    &__Title {
        display: block;
        font-size: 1.6rem;
        line-height: 1.35;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include media(l) {
            line-height: 1.65;
        }

        #{$root}--Card & {
            font-size: 1.2rem;
            @include media(m) {
                font-size: 1.6rem;
            }
        }
    }
}
