@import 'styles/includes.scss';

.NotFoundPage {
    background-color: $colorWhiteBackground;
    font-family: $fontBrand;
    flex: 1;
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: none;
    }

    &__Section {
        display: flex;

        &--Grid {
            @extend %container;
        }
    }

    &__Content {
        margin: 4rem 0;
    }

    &__Title {
        @extend %h1;
        margin-bottom: 5rem;
    }

    &__Text {
        @extend %body-copy;
    }
}
