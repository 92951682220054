@import 'styles/includes.scss';

.EventIcon {
  width: 20px;
  height: 20px;

  &--SmallMobile {
    width: 16px;
    height: 16px;

    @include media(ls) {
      width: 20px;
      height: 20px;
    }

    @include media(l) {
      width: 24px;
      height: 24px;
    }
  }
}
