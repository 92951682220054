@import 'styles/includes.scss';

.Survey {
    position: fixed;
    bottom: 0;
    right: 20px;
    z-index: 9998; // One below the Wagtail bird
    filter: drop-shadow(0 0 32px rgba(151, 151, 151, 0.2));

    &--Expanded {
        width: calc(100% - 40px);
        max-width: 428px;
    }

    &--Collapsed {
        width: 200px;
    }

    &__CloseButton {
        position: absolute;
        right: 0;
        top: -21px;
        width: 42px;
        height: 42px;
        border: 0;
        background: transparent;
        padding: 0;

        &::after {
            content: '';
            display: block;
            margin: auto;

            width: 30px;
            height: 30px;
            border: 0;
            background-image: url('#{$assetPath}img/cross.svg');
            background-color: #d9d9d9;
            background-repeat: no-repeat;
            background-size: 26px;
            background-position: 5px 5px;
            border-radius: 50%;
        }
    }

    &__CollapsedButton {
        padding: 16px;
        font-size: 1.4rem;
        background-color: white;
        appearance: none;

        display: block;
        border: 0;
        width: 100%;
        text-align: left;
        font-family: $fontBrand;
        font-size-adjust: 0.45;

        @include media(ml) {
            font-size: 1.6rem;
        }
    }

    &__Header {
        background-color: $colorWhiteBackground;
        padding: 16px 16px 4px 16px;
        font-size: 1.4rem;
    }

    &__Steps {
        text-align: center;
        font-size: 1.2rem;
        color: $colorGray500;
        margin-left: auto;
        margin-right: auto;
    }

    &__HeaderStatus {
        font-size: 1.2rem;
        letter-spacing: 2.6px;
        text-transform: uppercase;
    }

    &__HeaderTitle {
        margin-top: 8px;
        font-weight: bold;

        @include media(ml) {
            font-size: 1.6rem;
        }
    }

    &__Content {
        background-color: $colorWhiteBackground;
        padding: 8px 16px 16px 16px;
    }

    &__ThankYouStep {
        text-align: center;
        padding: 16px 0;
    }

    &__ThankYouStepText {
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 24px;
    }

    &__Nav {
        display: flex;
        margin-top: 27px;
        justify-content: space-between;
        align-items: center;
    }
}
