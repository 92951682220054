@import 'styles/includes.scss';

.ButtonRound {
    display: inline-block;
    position: relative;

    &__Link {
        border-radius: 50%;
        border: none;
        background: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 50000;
    }
    .ButtonRound__Link:focus + .ButtonRound--Active,
    .ButtonRound__Link:active + .ButtonRound--Active,
    .ButtonRound__Link:hover + .ButtonRound--Active {
        background-color: $colorPink;

        svg {
            color: $colorBlack800;
        }
    }
}
