@import 'styles/includes.scss';

.Modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(24, 24, 24, 0.4);
    z-index: 10001;

    &--Black {
        background-color: $colorBlack800;
    }

    &__ChildrenWrapper {
        @extend %container;
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}
