@import 'styles/includes.scss';

.MyListEvents {
    display: flex;
    flex-direction: column-reverse;

    @include media(ml) {
        flex-direction: row;
    }

    &__Events {
        flex-basis: 100%;
        background-color: $colorWhite;

        @include media(m) {
            flex-basis: calc(809 / 1440 * 100%);
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            ::-webkit-scrollbar {
                display: none;
            }
        }

        @include media(ml) {
            //the actual size of EventList in Zeplin
            flex-basis: calc(889 / 1440 * 100%);
        }

        // &--HalfGrid {
        //     padding: 0 $spacingL;

        //     @include media(ml) {
        //         padding-left: $spacingXl * 3;
        //         padding-right: 0;
        //     }

        //     @include media(l) {
        //         padding-left: $spacingXl * 4;
        //         padding-right: 0;
        //     }

        //     @include media(xl) {
        //         padding-left: calc((100% - #{map-get($maxwidths, xl)}) / 2);
        //         padding-right: 0;
        //     }
        // }
    }

    &__EventListWrapper {
        background-color: $colorWhite;
        margin-top: 40px;
        padding-bottom: 16px;
        overflow: scroll;
        height: 50rem;

        @include media(m) {
            flex-basis: calc(809 / 1440 * 100%);
            height: 90rem;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            ::-webkit-scrollbar {
                display: none;
            }
            margin-top: 0;
            padding-top: 40px;
        }

        @include media(ml) {
            flex-basis: calc(889 / 1440 * 100%);
            padding-right: 2rem;
        }
    }

    &__MapWrapper {
        padding: 0;
        flex-basis: 0;
        height: 90rem;

        @include media(m) {
            margin: 0;
            flex-basis: calc(631 / 1440 * 100%);
            min-width: calc(631 / 1440 * 100%);
        }

        @include media(ml) {
            flex-basis: calc(551 / 1440 * 100%);
            min-width: calc(551 / 1440 * 100%);
        }
    }
}
