@import 'styles/includes.scss';

.ArticleEntry {
    font-family: $fontBrand;
    color: $colorBlack800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 500;
    background-color: $colorGray10;

    @include media(l) {
        width: 75rem;
    }

    &__Label {
        font-size: 1.4rem;
        text-align: left;
        letter-spacing: 2.6px;
        margin-bottom: $spacingM;
        text-transform: uppercase;
        display: block;

        @include media(l) {
            font-size: 1.6rem;
            margin-bottom: $spacingXl;
        }

        .ArticleEntry--SmallFonts & {
            font-size: 1.2rem;
            margin-bottom: $spacingL;

            @include media(l) {
                margin-bottom: $spacingL;
                font-size: 1.4rem;
            }
        }
    }

    &__Title {
        @extend %h1;
        margin-bottom: $spacingXl;

        @include media(l) {
            margin-bottom: $spacingL * 2;
        }

        .ArticleEntry--SmallFonts & {
            @extend %h2;
            margin-bottom: $spacingXl;
        }
    }

    &__PublishDate {
        font-size: 1.2rem;
        letter-spacing: 2.63px;
        color: $colorGray700;
        text-transform: uppercase;
        margin-bottom: $spacingL * 2;

        @include media(l) {
            font-size: 1.4rem;
            margin-bottom: $spacingXl * 2;
        }
    }

    &__MetaContainer {
        display: flex;
        align-items: center;
        margin-bottom: $spacingM * 3;

        @include media(m) {
            margin-bottom: $spacingXl * 2;
        }
    }

    &__Preamble {
        @extend %preamble;
        color: $colorBlack750;
        max-width: $maxWidthText;
        margin-bottom: $spacingXl;

        @include media(m) {
            margin-bottom: $spacingM * 3;
        }
    }

    &__LabeledIconWrapper {
        margin-right: $spacingXl;

        @include media(l) {
            margin-right: $spacingM * 3;
        }
    }
}
