@import 'styles/includes.scss';

.EmergencyMessage {
    background-color: $colorOrange800;
    padding: $spacingS 0;

    @include media(m) {
        padding: $spacingL 0;
    }

    &__Content {
        @extend %container;
        display: flex;
        align-items: center;
    }

    &__Text {
        @extend %body-2;
        color: white;
        margin-left: 1.2rem;

        & a {
            color: white;
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            border-bottom: white thin solid;

            &:after {
                content: '';
                margin-left: 6px;
                background-image: url('#{$assetPath}img/arrow-white.svg');
                display: inline-block;
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            &[href*="//"]:not([href*="visitstockholm"]):after {
                background-image: url('#{$assetPath}img/external-white.svg');
            }

        }
    }
}
