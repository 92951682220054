@import 'styles/includes.scss';

.MyListPage {
    background-color: $colorWhiteBackground;
   flex: 1;
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: none;
    }

    &__Main {
        display: block;
    }

    &__Section {
        display: flex;

        &--Grid {
            @extend %container;
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;

        @include media(m) {
            max-width: calc(749 / 1260 * 100%);
        }

        .MyListPage__ExtendedByline {
            display: block;

            @include media(m) {
                display: none;
            }
        }
    }

    &__Row {
        margin-bottom: $spacingL * 2;
    }

    &__Aside {
        display: none;
        max-width: calc(511 / 1260 * 100%);

        @include media(m) {
            display: flex;
            width: 100%;

            justify-content: flex-end;
            margin-left: $spacingXl;
            margin-top: $spacingXl * 2;
        }
    }
}
