@import 'styles/includes.scss';

.EventListPage {
    $root: &;
    flex: 1;

    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        flex: none;
    }

    &__ContentWrap {
        padding-bottom: 8rem;
    }

    &__NoHitsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-top: 7.6rem;

        @include media(ls) {
            margin-top: 6rem;
            padding-top: 7.2rem;
        }

        &::after {
            content: '';
            position: absolute;
            top: 37px;
            left: 50%;
            transform: translateX(-50%);
            height: 4rem;
            width: 4rem;
            background-image: url('#{$assetPath}img/no-hits.svg');
            background-repeat: no-repeat;

            @include media(ls) {
                top: 0;
                height: 4.8rem;
                width: 4.8rem;
            }
        }
    }

    &__NoHitsTitle,
    &__NoHitsText {
        text-align: center;
    }

    &__NoHitsTitle {
        @extend %h3;
        margin-bottom: 0.8rem;

        @include media(ls) {
            margin-bottom: 1.6rem;
        }
    }

    &__Content {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        min-height: 80dvh;
        position: relative;

        @include media(ls) {
            padding-top: 22px;
        }

        &:before {
            transition: all 0.3s ease-in-out;
            content: '';
            position: absolute;
            // height: 163px;
            height: 263px;
            background-color: $colorBlack800;
            top: -120px;
            left: 0;
            width: 100%;

            @include media(ls) {
                top: 0;
                height: 293px;
            }

            @include media(l) {
                height: 263px;
            }
        }

        &--NoHits:before {
            top: -230px;
            @include media(ls) {
            }
        }

        &--Blank {
            opacity: 1;
        }
    }

    &__Plattan {
        transition: all 0.3s ease-in-out;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 115px;
        left: 0;
        border-right: 117px solid transparent;
        border-bottom: 143px solid $colorWhiteBackground;

        @include media(ls) {
            top: 150px;
            z-index: 1;
        }

        .EventListPage__Content--NoHits & {
            top: 0px;
            @include media(ls) {
                top: -73px;
            }
            @include media(l) {
                top: -103px;
            }
        }
    }

    &__Background {
        position: relative;
        z-index: 1;
        padding-bottom: 4rem;

        @include media(m) {
            padding-top: 6.8rem;
            padding-bottom: 6.4rem;
        }

        &--Black {
            background: $colorBlack800;
        }
    }

    &__TopWrapper {
        @extend %container;
        display: flex;
        flex-direction: column;
        margin-bottom: 2.2rem;

        @include media(m) {
            margin-bottom: 4rem;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__Hero {
        color: #fff;
        @extend %h1;
        font-size-adjust: none;

        margin-bottom: 1.8rem;

        @include media(m) {
            margin-bottom: 0;
        }
    }

    &__FiltersWrap {
        display: flex;
        @extend %container;
        flex-wrap: wrap;

        @include media(m) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }
    &__CatWrap {
        width: 100%;
        min-height: 4.8rem;
        @include media(m) {
            width: calc(100% - 500px);

            html[lang=sv] & {
                width: calc(100% - 430px);
            }
        }

        &--ShowSelectedCategory {
            @include media(m) {
                width: calc(100% - 328px);

                html[lang=sv] & {
                    width: calc(100% - 259px);
                }
            }
        }
    }
    &__Filters {
        display: flex;
        gap: 12px;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 10px;
        position: relative;

        @include media(m) {
            &:after {
                position: absolute;
                content: '';
                background: $colorGray700;
                display: block;
                height: 32px;
                width: 1px;
                top: 40%;
                transform: translateY(-50%);
                left: 0;
            }
            gap: 16px;
            margin-top: 0;
            width: 328px;
            justify-content: flex-end;
            flex-wrap: nowrap;

            html[lang=sv] & {
            width: 259px;
            }
        }
    }

    &__FilterButton {
        font-family: $fontBrand;
        padding: 5px 12px;
        border-radius: 45px;
        border: 1px solid $colorBlack750;
        background: transparent;
        color: $colorGray40;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.3px;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: border 0.1s ease-in-out, background-color 0.3s ease-in-out;

        &:hover {
            border: 1px solid $colorPink;
        }

        @include media(m) {
            gap: 10px;
            font-size: 1.6rem;
            height: 44px;
            padding: 10px 16px;
            background: $colorBlack750;
            border: 1px solid $colorBlack750;
        }
    }

    &__FilterIcon {
        position: relative;
        height: 16px;
        width: 16px;
        margin: 2px;
        border: 1px solid $colorGray40;
        border-radius: 2px;
        transition: border 0.1s ease-in-out, background-color 0.1s ease-in-out;

        @include media(m) {
            height: 17px;
            width: 17px;
            margin: 0;
        }

        .EventListPage__FilterButton--Active & {
            border: 1px solid $colorPink;
            background-color: $colorPink;
        }

        &:after {
            .EventListPage__FilterButton--Active & {
                content: '';
                background-image: url(#{$assetPath}img/check-bold-white.svg);
                height: 10px;
                width: 10px;
                position: absolute;
                background-repeat: no-repeat;
                background-size: contain;
                top: 59%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__Clear {
        @extend %container;
        // min-height: 11.1px;
        // transform: translateY(25px);
        position: relative;
        display: flex;
        align-items: center;
        min-height: 11.1px;

        // &--NoHits {
        //     min-height: 47.1px;
        // }
        transition: margin-top 0.1s ease-in;

        margin-top: 24px;
        margin-bottom: 12px;

        &--ClearBtnShown {
            margin-top: 51px;
        }

        @include media(m) {
            gap: 16px;
            margin-top: 10px;
            margin-bottom: 0;
            transform: translateY(0);
            justify-content: space-between;
            align-items: flex-start;
            min-height: 34px;

            &--ClearBtnShown {
                margin-top: 38px;
            }
        }
    }

    &__ClearButtonWrapper {
        position: absolute;
        bottom: -45px;
        right: 16px;
        transition: all 0.3s ease-in-out;

        &--NoHits {
            bottom: -38px;
        }

        @include media(m) {
            position: static;
            align-self: flex-start;
            // padding-top: 1.6rem;
        }
    }

    &__ClearButton {
        position: relative;
        padding: 0.4rem 0.4rem 0.4rem 2.8rem;
        @extend %body-2;
        font-size: 1.4rem;
        text-decoration: underline;
        color: $colorGray10;
        background: none;
        border: none;
        // margin-left: 13px;

        @include media(ls) {
            font-size: 1.6rem;
            // margin-left: 0;
        }
    }

    &__ClearIcon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__StreamField {
        display: flex;
        flex-direction: column;
        gap: 56px;

        @include media(ls) {
            gap: 72px;
        }
    }
}
