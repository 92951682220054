@import 'styles/includes.scss';
@import 'styles/variables.scss';

.GuideFilter {
    margin: 4px;
    margin-right: 1.6rem;
    color: $colorBlack800;
    font-family: $fontBrand;
    border-radius: 50px;
    padding: 0.6rem 1.6rem 0.8rem;
    transition: transform 0.3s, border 0.3s;
    display: inline-flex;
    align-items: center;
    opacity: 1;
    white-space: nowrap;
    cursor: pointer;
    height: 3.7rem;

    &:hover {
        transform: translateY(-4px);
        border: 1px solid $colorBlack800;
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
    }

    &--Active {
        //transform: translateY(-4px);
        background-color: $colorBlack800 !important;
        color: $colorWhite;
        transform: translateY(0px) !important;
    }

    &__Text {
        font-size: 1.4rem;
        letter-spacing: 0.02rem;
        text-transform: capitalize;
    }

    &--Blue {
        background-color: $colorBlue50;
        border: thin solid $colorBlue50;
    }

    &--Green {
        background-color: $colorGreen50;
        border: thin solid $colorGreen50;
    }

    &--Orange {
        background-color: $colorOrange50;
        border: thin solid $colorOrange50;
    }

    &--Purple {
        background-color: $colorPurple50;
        border: thin solid $colorPurple50;
    }

    &--Pink {
        background-color: $colorPink300;
        border: thin solid $colorPink300;
    }

    &--Gray {
        background-color: $colorGray100;
        border: thin solid $colorGray100;
    }

    &--White {
        background-color: white;
        border: thin solid $colorBlack800;
    }
}
