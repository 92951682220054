@import 'styles/includes.scss';

.Breadcrumbs {
    font-family: $fontBrand;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    color: $colorWhite;
    padding: $spacingS 0;
    background-color: $colorBlack800;
    border-top: 1px solid $colorGray700;

    @include media(m) {
        color: $colorGray100;
    }

    &__Nav {
        @extend %container;
    }

    &__List {
        list-style: none;
        display: flex;
    }

    &__Item {
        display: flex;
        max-width: 50%;

        @include media(s) {
            max-width: unset;
        }

        .Icon {
            position: relative;
            margin: 0 0.2rem;
        }
    }

    &__Link {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background: $colorWhiteBackground;
                bottom: 0;
                left: 0;

                @include media(m) {
                    background: $colorGray100;
                }
            }
        }
    }

    &__LinkText {
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
