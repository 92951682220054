@import 'styles/variables.scss';

.DropdownLinks {
    $root: &;
    position: relative;
    z-index: 1;

    &__Button {
        display: flex;
        align-items: center;
        // @extend %nav-subtitles;
        color: $colorWhite;
        background-color: $colorBlack800;
        padding: 12px;
        border: 1px solid transparent;
        border-bottom: none;
        width: 100%;

        &--Open {
            border: 1px solid $colorBlack750;
            border-bottom: none;
        }

        &:focus {
            outline: none !important;
            color: $colorPink;
        }

        #{$root}--isSmall & {
            padding: 6px 12px;
            height: 30px;
        }
    }

    &__Title {
        font-family: $fontBrand;
        #{$root}--isSmall & {
            font-size: 11px;
            font-family: $fontBrand;
            font-weight: normal;
            line-height: 20px;
            padding-bottom: 2px;
        }
    }

    &__Icon {
        transform: rotate(90deg);
        margin-left: 10px;
        transition: transform 0.2s ease-in-out;

        &--Open {
            transform: rotate(-90deg);
        }

        #{$root}--isSmall & {
            margin-left: 6px;
        }
    }

    &__WebIcon {
        padding-right: 6px;
    }

    &__Menu {
        display: none;
        position: absolute;
        width: 100%;
        flex-direction: column;
        background-color: $colorBlack800;
        border-radius: 0 0 12px 12px;
        border: 1px solid $colorBlack750;
        border-top: none;
        margin-top: -2px;

        &--Open {
            display: flex;
        }
    }

    &__MenuItem {
        @extend %nav-subtitles;
        color: $colorWhite;
        text-decoration: none;
        padding: 20px;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            color: $colorPink;
        }

        #{$root}--isSmall & {
            font-size: 1.2rem !important;
            padding: 16px 12px 16px 34px;
        }
    }
}
