@import 'styles/includes.scss';

.StreamField {
    &__Component--EventListPage > div {
        padding-top: 0!important;
    }

    //if there are two white component in a row, make component padding smaller
    //if there is a white component followed by a mixed black/white one, make padding smaller
    &__Component--White + &__Component--White > div,
    &__Component--White + &__Component--Mixed > div {
        padding-top: 3.2rem;
    }

    //if there are two black components in a row, make component padding smaller
    //if there is a mixed black/white component followed by a black one, make padding smaller
    &__Component--Black + &__Component--Black > div,
    &__Component--Mixed + &__Component--Black > div {
        padding-top: 3.2rem;
    }

    //Add shape between black and white components
    &__Component--Black + &__Component--White > div,
    &__Component--Black + &__Component--Mixed > div {
        position: relative;
        padding-top: 3.2rem;
        overflow: visible;

        &:after {
            position: absolute;
            width: 100%;
            top: -120px;
            height: 120px;
            content: '';
            background-color: $colorWhiteBackground;

            @include media(ml) {
                top: -220px;
                height: 220px;
            }
        }

        &:before {
            position: absolute;
            display: block;
            top: -225px;
            width: 91px;
            height: 114px;
            content:"";
            background-image: url(#{$assetPath}img/divider-triangle.svg);
            background-size: contain;

            @include media(ml) {
                top: -345px;
                width: 117px;
                height: 143px;
            }

            @include media(l) {
                top: -520px;
                width: 356px;
                height: 443px;
            }
        }
    }

    &__Component {
        &--VideoCarousel {
            padding: 50px 0;
        }
    }
}
