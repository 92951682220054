@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes loader {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeInAndScale {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    100% {
        opacity: 1;
        transform: scale(1.05);
    }
}

@keyframes zoomin {
    0% {
        transform: scale(1.2, 1.2);
    }
    50% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1.2, 1.2);
    }
}
